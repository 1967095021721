import "./App.css";
import { useNavigate } from "react-router-dom";
import Image4 from "./asset/footer-logo.svg";
import Image16 from "./asset/logo2.png";
import Image5 from "./asset/salt-image-1.jpg";
import Image6 from "./asset/salt-image-2.jpg";
import Image7 from "./asset/salt-image-3.jpg";
import Image8 from "./asset/salt-image-4.jpg";
import Image9 from "./asset/salt-image-5.jpg";
import Image10 from "./asset/salt-image-6.jpg";
import Image11 from "./asset/salt-image-7.jpg";
import Image12 from "./asset/salt-image-8.jpg";

function Product() {
  const navigate = useNavigate();
  return (
    <>
      <header>
        <div className="flex-container  bg-header">
          <div className="row justify-content-around   mx-auto ">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-2">
              <img src={Image16} alt="" className="logo-pink" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex  justify-content-end  ">
              <button className="header-button poppins-light  hedder-button" onClick={() => navigate("/")}>
                <b>Home</b>
              </button>
              <button
                className="header-button poppins-light hedder-button"
                onClick={() => navigate("/about")}
              >
                <b> About</b>
              </button>
              <button className="header-button poppins-light hedder-button" onClick={() => navigate("/product")}>
                <b>Product</b>
              </button>
              <button className="header-button poppins-light" onClick={() => navigate("/contact")} style={{ marginRight: "0" }}>
                <b> Contact</b>
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className="container-xl mt-5">
        <span className=" font-color poppins-semibold-italic ">
          <u>Product</u>
        </span>
      </div>
      <section className=" container-xl mt-5">
        <div className="text-center">
          <h5 className="poppins-light text-start">
            Shindhalu salt is highly valued in the Ayurveda system of medicine
            as it offers numerous health benefits right from healthy skin and
            hair to weight loss. It is known to be very effective when you
            replace your daily salt with this purest form of salt. To sum up,
            from the perspective of a consumer, Himalayan salt minerals and
            nutrients do not play an important role due to the low content
            level, and therefore it is almost similar to regular or sea salt
            concerning the issue of health benefits. However, Himalayan salt has
            a pink color and a distinctive aroma that make this product unique
            and indispensable for some people.
          </h5>
        </div>
      </section>
      {/* product */}
      <section className="container-xl  mt-5 ">
        <div className="container margin-top1  ">
          <div className="row ">
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2">
              <img
                src={Image5} alt=""
                className="product-image"
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 p-2">
              <img
                src={Image6} alt=""
                className="product-image"
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 p-2">
              <img
                src={Image7} alt=""
                className="product-image"
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 p-2 ">
              <img
                src={Image8} alt=""
                className="product-image"
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4">
              <img
                src={Image9} alt=""
                className="product-image"
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 p-2 mt-3">
              <img
                src={Image10} alt=""
                className="product-image"
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 p-2 mt-3">
              <img
                src={Image11} alt=""
                className="product-image"
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 p-2 mt-3 ">
              <img
                src={Image12} alt=""
                className="product-image"
              />
            </div>
          </div>
        </div>
      </section>

     {/* fotter start */}
     <section className="mt-5">
        <div className="fotter-color">
          <div className="container-lg p-4">
            <div className="row ">
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12  p-5">
                <img src={Image4} alt="" className="foter-logo" />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12   ">
                <p className="fotter-font1 poppins-light">Information</p>
                <div className="d-flex flex-column  mb-3">
                  <p className="fotter-font2 poppins-light">
                    Home
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    About
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    Product
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    Contact
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
                <p className="fotter-font1 poppins-light">Get In Touch</p>
                <p className="fotter-font2 poppins-light">
                  Customer Care No. :+919504991049
                </p>
               
                <p className="fotter-font2 poppins-light mt-4">
                  customercare@kankuben.com
                </p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
                <p className="fotter-font1 poppins-light">Recent Post</p>
                <p className="fotter-font3 poppins-light">
                  <u>RK NUTRI FOOD</u>
                </p>
                <p className="fotter-font2 poppins-light">
                  survey No 96/2,at Jodhpar Nadi, jodhpar Bhadiyad
                  Road,Morbi-363642
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* fotter end */}
    </>
  );
}

export default Product;
