import "./App.css";
import { useNavigate } from "react-router-dom";
import Image from "./asset/carousel-1.jpg";
import Image1 from "./asset/carousel-2.jpg";
import Image2 from "./asset/carousel-3.jpg";
import Image3 from "./asset/carousel-4.jpg";
import Image4 from "./asset/footer-logo.svg";
import Image16 from "./asset/logo2.png";
import Image13 from "./asset/Empathy.svg";
import Image14 from "./asset/Integrity.svg";
import Image15 from "./asset/Agility.svg";

function App() {
  const navigate = useNavigate();
  return (
    <>
      <header>
        <div className="flex-container  bg-header">
          <div className="row justify-content-around   mx-auto ">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-2">
              <img src={Image16} alt="" className="logo-pink" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex  justify-content-end  ">
              <button className="header-button poppins-light  hedder-button" onClick={() => navigate("/")}>
                <b>Home</b>
              </button>
              <button
                className="header-button poppins-light hedder-button"
                onClick={() => navigate("/about")}
              >
                <b> About</b>
              </button>
              <button className="header-button poppins-light hedder-button" onClick={() => navigate("/product")}>
                <b>Product</b>
              </button>
              <button className="header-button poppins-light" onClick={() => navigate("/contact")} style={{ marginRight: "0" }}>
                <b> Contact</b>
              </button>
            </div>
          </div>
        </div>
      </header>
      {/* carousel */}
      <section>
        <div className="">
          <div id="demo" className="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#demo"
                data-bs-slide-to="0"
                className="active"
              ></button>
              <button
                type="button"
                data-bs-target="#demo"
                data-bs-slide-to="1"
              ></button>
              <button
                type="button"
                data-bs-target="#demo"
                data-bs-slide-to="2"
              ></button>
              <button
                type="button"
                data-bs-target="#demo"
                data-bs-slide-to="3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={Image} alt=""
                  className="d-block carousel-image "

                />
              </div>
              <div className="carousel-item">
                <img
                  src={Image1} alt=""
                  className="d-block carousel-image"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Image2} alt=""
                  className="d-block carousel-image"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Image3} alt=""
                  className="d-block carousel-image"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#demo"
              data-bs-slide="prev"
            >
              <span class="carousel-control-prev-icon"></span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#demo"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon"></span>
            </button>
          </div>
        </div>
      </section>
      <section className="container d-flex ">
        <div className="text-center mt-5">
          <span className="mt-5 font-color poppins-semibold-italic ">
            <u>May aid skin health</u>
          </span>
          <h5 className="mt-5 poppins-light  text-start">
            Shindha Salt may boost skin health.
            Ayurvedic medicine asserts that rock salts can cleanse, strengthen, and rejuvenate skin tissue.
            Although evidence is lacking for many of these claims, research suggests that fluids and electrolytes may treat certain types of dermatitis.Exfoliative dermatitis, also known as erythroderma, is an uncommon but serious skin disorder that family physicians must be able to recognize and treat appropriately.
            Plus, a 6-week study found that bathing in a magnesium solution containing 5% Dead Sea salt for 15 minutes per day significantly reduced skin roughness and redness while significantly improving skin hydration. Magnesium salts, the prevalent minerals in Dead Sea water, are known to exhibit favorable effects in inflammatory diseases. We examined the efficacy of bathing atopic subjects in a salt rich in magnesium chloride from deep layers of the Dead Sea (Mavena(R) Dermaline Mg(46) Dead Sea salt, Mavena AG, Belp, Switzerland).
            Volunteers with atopic dry skin submerged one forearm for 15 min in a bath solution containing 5% Dead Sea salt.
            Since sea salt and rock salts are very similar in their chemical composition,  Shindha Salt may provide similar benefits.
          </h5>
        </div>
      </section>
      <section className="container">
        <div className="text-center mt-5">
          <span className="mt-5 font-color poppins-semibold-italic ">
            <u>First Mentions of Pink Salt</u>
          </span>
          <h5 className="mt-5 poppins-light text-start ">
            Despite the local legend that claims salt mines to be discovered by
            the army of Alexander the Great, first documents about Himalayan
            salt mention its extraction in the 13th century. Most of the salt
            nowadays comes from the Khewra Salt Mine in Pakistan. There, it is
            being cut in bulks and exported. The procession of the salt takes
            place abroad.
          </h5>
        </div>
      </section>
      <section className="container">
        <div className="text-center mt-5">
          <span className="mt-5 font-color poppins-semibold-italic ">
            <u>Why Himalayan Salt is Pink</u>
          </span>
          <h5 className="mt-5 poppins-light text-start">
            As far as chemical composition is concerned, Himalayan salt
            resembles table salt with mineral impurities. It contains 95-98% of
            sodium chloride, 2-4% polyhalite (hydrated sulfate of potassium,
            calcium, and magnesium), 0.01% fluorine, 0.01% iodine, as well as
            numerous trace elements in small quantities. What gives it a pink
            color is one of those trace elements, in particular, iron oxide.
            Salt crystals have a color from almost white to transparent colored.
            Impurities of polyhalite and trace elements in some deposits give
            the crystals pink, reddish, or meat-red color.
          </h5>
        </div>
      </section>
      <div className="container-xl padding-1 mt-5">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center ">
            <img src={Image13} alt="" className="icon" />
            <p className="poppins-light mt-3 font-color"><b>Empathy</b></p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center  ">
            <img src={Image14} alt="" className="icon" />
            <p className="poppins-light mt-3 font-color"><b>Integrity</b></p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center">
            <img src={Image15} alt="" className="icon" />
            <p className="poppins-light mt-3 font-color"><b>Agility</b></p>
          </div>
        </div>
      </div>

      {/* fotter start */}
      <section className="mt-5">
        <div className="fotter-color">
          <div className="container-lg p-4">
            <div className="row ">
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12  p-5">
                <img src={Image4} alt="" className="foter-logo" />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12   ">
                <p className="fotter-font1 poppins-light">Information</p>
                <div className="d-flex flex-column  mb-3">
                  <p className="fotter-font2 poppins-light">
                    Home
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    About
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    Product
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    Contact
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
                <p className="fotter-font1 poppins-light">Get In Touch</p>
                <p className="fotter-font2 poppins-light">
                  Customer Care No. :+919504991049
                </p>

                <p className="fotter-font2 poppins-light mt-4">
                  customercare@kankuben.com
                </p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
                <p className="fotter-font1 poppins-light">Recent Post</p>
                <p className="fotter-font3 poppins-light">
                  <u>RK NUTRI FOOD</u>
                </p>
                <p className="fotter-font2 poppins-light">
                  survey No 96/2,at Jodhpar Nadi, jodhpar Bhadiyad
                  Road,Morbi-363642
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* fotter end */}
    </>
  );
}

export default App;
