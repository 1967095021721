
import "./App.css";
import { useNavigate } from "react-router-dom";
import Image4 from "./asset/footer-logo.svg";
import Image16 from "./asset/logo2.png";


function Contact() {
  const navigate = useNavigate();
  return (
    <>
       <header>
        <div className="flex-container  bg-header">
          <div className="row justify-content-around   mx-auto ">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-2">
              <img src={Image16} alt="" className="logo-pink" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex  justify-content-end  ">
              <button className="header-button poppins-light  hedder-button" onClick={() => navigate("/")}>
                <b>Home</b>
              </button>
              <button
                className="header-button poppins-light hedder-button"
                onClick={() => navigate("/about")}
              >
                <b> About</b>
              </button>
              <button className="header-button poppins-light hedder-button" onClick={() => navigate("/product")}>
                <b>Product</b>
              </button>
              <button className="header-button poppins-light" onClick={() => navigate("/contact")} style={{ marginRight: "0" }}>
                <b> Contact</b>
              </button>
            </div>
          </div>
        </div>
      </header>
      <section>
      <div className="container-xl mt-5">
        <span className=" font-color poppins-semibold-italic ">
          <u>About</u>
        </span>
      </div>
      </section>
      <section>
        <div className="container margin-top2 mt-5">
          <div className="row  ">
            <div className="col-xl-8 col-lg-6 col-md-12 col-sm-6 col-12 flex-clounm ">
              <div>
                <p className="txt11  contact-get-txt">Get In Touch.</p>
              </div>
              <div className="flex-clounm">
                <input type="text" id="full name" name="full name" placeholder="Full Name*" required
                  className="border-box mt-3" />
                <input type="email" id="email address" name="full name" placeholder="Email Address*" required
                  className="border-box mt-5" />
                <input type="text" id="contact No." name="contact No." placeholder="Contact No.*" required
                  className="border-box mt-5" />
                <input type="text" id="message" name="message" placeholder="Message*" required
                  className="border-box mt-5" />
                <div className="mt-5">
                  <button className="button-submit">
                    submit
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-6 col-12 mt-3 ">
              <div>
                <p className="txt11  contact-get-txt">Contact Details.</p>
              </div>
              <div className="flex-clounm">
                <p className="email-txt">Email Address</p>
                <p className="email-txt1">info@kankuben.com
                </p>
                <p className="email-txt1">customercare@kankuben.com
                </p>
              </div>
              <div className="flex-clounm mt-3">
                <p className="email-txt">Phone Number</p>
                <p className="email-txt1">+91 95049 91049
                </p>
              </div>
              <div className="flex-clounm mt-3">
                <p className="email-txt">Address</p>
                <p className="email-txt1  email-txt3">survey No 96/2,at Jodhpar Nadi, jodhpar Bhadiyad
                Road,Morbi-363642
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
     {/* fotter start */}
     <section className="mt-5">
        <div className="fotter-color">
          <div className="container-lg p-4">
            <div className="row ">
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12  p-5">
                <img src={Image4} alt="" className="foter-logo" />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12   ">
                <p className="fotter-font1 poppins-light">Information</p>
                <div className="d-flex flex-column  mb-3">
                  <p className="fotter-font2 poppins-light">
                    Home
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    About
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    Product
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    Contact
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
                <p className="fotter-font1 poppins-light">Get In Touch</p>
                <p className="fotter-font2 poppins-light">
                  Customer Care No. :+919504991049
                </p>
               
                <p className="fotter-font2 poppins-light mt-4">
                  customercare@kankuben.com
                </p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
                <p className="fotter-font1 poppins-light">Recent Post</p>
                <p className="fotter-font3 poppins-light">
                  <u>RK NUTRI FOOD</u>
                </p>
                <p className="fotter-font2 poppins-light">
                  survey No 96/2,at Jodhpar Nadi, jodhpar Bhadiyad
                  Road,Morbi-363642
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* fotter end */}
    </>
  );
}

export default Contact;
