import "./App.css";
import { useNavigate } from "react-router-dom";
import Image4 from "./asset/footer-logo.svg";
import Image16 from "./asset/logo2.png";
import Image17 from "./asset/mission.gif";
import Image18 from "./asset/vision.gif";
import Image19 from "./asset/values.gif";

function About() {
  const navigate = useNavigate();
  return (
    <>
      <header>
        <div className="flex-container  bg-header">
          <div className="row justify-content-around   mx-auto ">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-2">
              <img src={Image16} alt="" className="logo-pink" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex  justify-content-end  ">
              <button className="header-button poppins-light  hedder-button" onClick={() => navigate("/")}>
                <b>Home</b>
              </button>
              <button
                className="header-button poppins-light hedder-button"
                onClick={() => navigate("/about")}
              >
                <b>About</b>
              </button>
              <button className="header-button poppins-light hedder-button" onClick={() => navigate("/product")}>
                <b>Product</b>
              </button>
              <button className="header-button poppins-light" onClick={() => navigate("/contact")} style={{ marginRight: "0" }}>
                <b> Contact</b>
              </button>
            </div>
          </div>
          
        </div>
      </header>
      <section>
        <div className="container-xl mt-5">
          <span className=" font-color poppins-semibold-italic ">
            <u>About</u>
          </span>
        </div>
      </section>
      <section className=" container-xl mt-5">
        <div className="text-center">
          <h5 className="poppins-light text-start">
            Sindhalu Salt lmmuno offers consumer a category-first innovation with the unique proposition of added Zinc.Along with the right amount if iodine, shindhalu salt lmmuno has added zinc which helps in the normal functioning of the immune system. Sindhalu Salt lmmuno is a simple switch for consumers who are trying to find more ways to address their family's wee-being conveniently. With Shindhalu Salt lmmuno, Shindhalu Salt is strenghening its commitment towards contributing to the nation's health.
          </h5>
        </div>
      </section>
      <section className="container-xl  ">
        <div className="container margin-top1">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="box mt-5  ipadmini">
                <div className="flex-colunm p-5  ">
                  <img
                    src={Image17} alt=""
                    style={{ height: " 70px", width: "70px" }}
                  />
                  <p className="Architectural p-2 lineUp poppins-light">
                    Our Mission
                  </p>
                  <p className="p-2 recent-work-p poppins-light">
                    Passionately growing and innovating every day.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="box mt-5  ipadmini">
                <div className="flex-colunm p-5 padding-top ">
                  <img
                    src={Image18} alt=""
                    style={{ height: " 70px", width: "70px" }}
                  />
                  <p className="Architectural p-2 margin-bottom lineUp poppins-light">
                    Our Vision
                  </p>
                  <p className="p-2 recent-work-p margin-bottom poppins-light">
                    To build better lives and thriving communities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
              <div className="box mt-5  ipadmini">
                <div className="flex-colunm p-5 padding-top">
                  <img
                    src={Image19} alt=""
                    style={{ height: " 70px", width: "70px" }}
                  />
                  <p className="Architectural p-2 margin-bottom lineUp poppins-light">
                    Our Value
                  </p>
                  <p className="p-2 recent-work-p margin-bottom poppins-light">
                    Taking pride and initiative, and nurturing the communities
                    we work in.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" container-xl mt-5">
        <div className="text-center">
          <h5 className="poppins-light text-start">
            We are committed to innovation and it continues to power our future.
            Our consumers can always expect products and offerings that are
            developed with their needs and convenience in mind.
          </h5>
        </div>
      </section>
      {/* fotter start */}
      <section className="mt-5">
        <div className="fotter-color">
          <div className="container-lg p-4">
            <div className="row ">
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12  p-5">
                <img src={Image4} alt="" className="foter-logo" />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12   ">
                <p className="fotter-font1 poppins-light">Information</p>
                <div className="d-flex flex-column  mb-3">
                  <p className="fotter-font2 poppins-light">
                    Home
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    About
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    Product
                  </p>
                  <p
                    className="fotter-font2 mt-1 poppins-light"
                  >
                    Contact
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
                <p className="fotter-font1 poppins-light">Get In Touch</p>
                <p className="fotter-font2 poppins-light">
                  Customer Care No. :+919504991049
                </p>

                <p className="fotter-font2 poppins-light mt-4">
                  customercare@kankuben.com
                </p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
                <p className="fotter-font1 poppins-light">Recent Post</p>
                <p className="fotter-font3 poppins-light">
                  <u>RK NUTRI FOOD</u>
                </p>
                <p className="fotter-font2 poppins-light">
                  survey No 96/2,at Jodhpar Nadi, jodhpar Bhadiyad
                  Road,Morbi-363642
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* fotter end */}
    </>
  );
}

export default About;
